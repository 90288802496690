<template>
  <div
    class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
    style="background-image: url('media/illustrations/progress-hd.png')"
  >
    <!--begin::Content-->
    <div class="d-flex flex-center flex-column flex-column-fluid p-10 pb-lg-20">
      <!--begin::Logo-->
      <a href="#" class="mb-12">
        <img
          alt="Logo"
          src="media/logos/printeepro_logo_dark.png"
          class="h-45px"
        />
      </a>
      <!--end::Logo-->
      <div class="position-absolute top-0 end-0 mt-11 me-11">
        <strong class="fs-5 me-2">{{ $gettext("Language") }}:</strong>
        <el-select
          class="w-275px"
          v-model="curLang"
          :placeholder="$gettext('Language')"
        >
          <el-option
            v-for="item in countries"
            :key="item.name"
            :label="item.name"
            :value="item.value"
            :selected="item.selected"
          >
            <a @click="setLang(item.value)">
              <div class="w-230px">
                <span class="symbol symbol-20px me-4">
                  <img class="rounded-1" :src="item.flag" :alt="item.name" />
                </span>
                {{ item.name }}
              </div>
            </a>
          </el-option>
        </el-select>
      </div>

      <!--begin::Wrapper-->
      <div class="bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
        <!--begin::Form-->
        <Form
          class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
          novalidate="novalidate"
          @submit="onSubmitRegister"
          id="kt_login_signup_form"
          :validation-schema="registration"
        >
          <!--begin::Heading-->
          <div class="mb-10 text-center">
            <!--begin::Title-->
            <h1 class="text-dark mb-3">{{ $gettext("Create an Account") }}</h1>
            <!--end::Title-->
          </div>
          <!--end::Heading-->

          <!--begin::Action-->
          <!--<button
            type="button"
            class="btn btn-light-primary fw-bolder w-100 mb-10"
          >
            <img
              alt="Logo"
              src="media/svg/brand-logos/google-icon.svg"
              class="h-20px me-3"
            />
            Sign in with Google
          </button>
          -->
          <!--end::Action-->

          <!--begin::Separator-->
          <!--<div class="d-flex align-items-center mb-10">
            <div class="border-bottom border-gray-300 mw-50 w-100"></div>
            <span class="fw-bold text-gray-400 fs-7 mx-2">OR</span>
            <div class="border-bottom border-gray-300 mw-50 w-100"></div>
          </div>-->
          <!--end::Separator-->

          <!--begin::Input group-->
          <div class="row fv-row mb-7">
            <div class="fv-row mb-7">
              <label class="form-label fw-bolder text-dark fs-6">
                {{ $gettext("Photo studio name") }}</label
              >
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :placeholder="$gettext('generic123')"
                name="code"
                autocomplete="on"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="code" />
                </div>
              </div>
            </div>
            <!--begin::Col-->
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6">{{
                $gettext("First Name")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :placeholder="$gettext('Name')"
                name="name"
                autocomplete="on"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="name" />
                </div>
              </div>
            </div>
            <!--end::Col-->

            <!--begin::Col-->
            <div class="col-xl-6">
              <label class="form-label fw-bolder text-dark fs-6">{{
                $gettext("Last Name")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                type="text"
                :placeholder="$gettext('Surname')"
                name="surname"
                autocomplete="on"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="surname" />
                </div>
              </div>
            </div>

            <div class="fv-row mb-7">
              <label class="form-label fw-bolder text-dark fs-6">{{
                $gettext("Phone number")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                type="phone"
                :placeholder="$gettext('+386xxxxxxxx')"
                name="phone"
                autocomplete="on"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="phone" />
                </div>
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <div class="row mb-7 fv-row">
            <div class="fv-row mb-7">
              <label class="form-label fw-bolder text-dark fs-6">{{
                $gettext("Contact information")
              }}</label>
              <Field name="contact" v-slot="{ field }">
                <textarea
                  class="form-control form-control-lg form-control-solid"
                  rows="4"
                  cols="50"
                  :placeholder="
                    $gettext('Address,City,Country').replace(/,/g, ',\n')
                  "
                  v-bind="field"
                  autocomplete="on"
                  name="contact"
                ></textarea>
              </Field>
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="contact" />
                </div>
              </div>
            </div>

            <!--begin::Input group-->
            <div class="fv-row mb-10">
              <label class="form-label fw-bolder text-dark fs-6">{{
                $gettext("Formats")
              }}</label>
              <el-select
                v-model="value"
                multiple
                :placeholder="$gettext('Select')"
                class="form-control form-control-lg form-control-solid"
              >
                <el-option
                  v-for="format in languageFormat"
                  :key="format"
                  :label="dynStr(format.translations, 'title')"
                  :value="format.id"
                >
                </el-option>
              </el-select>
            </div>
            <template v-for="(item, index) in value" :key="index">
              <div class="col-md-3">
                <label class="form-label fw-bolder text-dark fs-6">{{
                  findDynStrById(item, languageFormat, "title")
                }}</label>
                <Field
                  class="form-control form-control-lg form-control-solid"
                  min="0"
                  step="0.01"
                  type="number"
                  :placeholder="$gettext('Product price with VAT')"
                  :name="`format-${item}`"
                  autocomplete="on"
                />
                <div class="fv-plugins-message-container">
                  <div class="fv-help-block">
                    <ErrorMessage :name="`format-${item}`" />
                  </div>
                </div>
              </div>
            </template>
            <!--end::Input group-->

            <div class="fv-row mb-10">
              <label class="form-label fw-bolder text-dark fs-6">{{
                $gettext("Upload logo")
              }}</label>
              <Field
                class="form-control form-control-lg form-control-solid"
                type="file"
                accept="image/*"
                placeholder=""
                name="logo"
                autocomplete="off"
                @change="handleFileUpload($event)"
              />
              <div class="fv-plugins-message-container">
                <div class="fv-help-block">
                  <ErrorMessage name="logo" />
                </div>
              </div>
            </div>
          </div>

          <!--begin::Input group-->
          <div class="fv-row mb-10">
            <label class="form-check form-check-custom form-check-solid">
              <Field
                class="form-check-input"
                type="checkbox"
                name="toc"
                value="true"
              />
              <span class="form-check-label fw-bold text-gray-700 fs-6">
                {{ $gettext("I agree to") }}
                <a
                  href="https://printeepro.com/privacy"
                  class="ms-1 link-primary"
                  >{{ $gettext("Terms and conditions") }}</a
                >
              </span>
            </label>
            <div class="fv-plugins-message-container">
              <div class="fv-help-block">
                <ErrorMessage name="toc" />
              </div>
            </div>
          </div>
          <!--end::Input group-->

          <!--begin::Actions-->
          <div class="text-center">
            <button
              id="kt_sign_up_submit"
              ref="submitButton"
              type="submit"
              class="btn btn-lg btn-primary"
            >
              <span class="indicator-label">{{ $gettext("Submit") }}</span>
              <span class="indicator-progress">
                {{ $gettext("Please wait...") }}
                <span
                  class="spinner-border spinner-border-sm align-middle ms-2"
                ></span>
              </span>
            </button>
          </div>
          <!--end::Actions-->
        </Form>
        <!--end::Form-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--end::Content-->
  </div>
  <KTFooter></KTFooter>
</template>

<script lang="ts">
import KTFooter from "@/layout/footer/Footer.vue";
import { defineComponent, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { dynStr } from "@/core/services/DynamicTranslationService";
import FormData from "form-data";
import { useGettext } from "vue3-gettext";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "sign-up",
  components: {
    KTFooter,
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    const gettext = useGettext();
    const store = useStore();
    const router = useRouter();

    const languageFormat = [
      {
        id: 0,
        translations: [
          {
            /* eslint-disable @typescript-eslint/camelcase */
            languages_code: "en",
            title: "10 x 18 cm",
          },
        ],
      },
    ];
    const findDynStrById = function (id, trans, word) {
      for (const tran of trans) {
        if (tran.id == id) {
          return dynStr(tran.translations, word);
        }
      }
      return null;
    };
    const fileList = [];
    const file = "";
    const curLang = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";
    gettext.current = curLang;
    const value = [];
    return {
      store,
      router,
      file,
      fileList,
      curLang,
      value,
      languageFormat,
      dynStr,
      findDynStrById,
    };
  },
  created() {
    this.store.dispatch(Actions.FORMATS).then((result) => {
      this.languageFormat = result.data;
    });
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    onSubmitRegister(values, e) {
      console.log(values);
      console.log(e);
      //const submitButton = ref<HTMLElement | null>(null);
      const submitButton = e.evt.target as HTMLElement;
      console.log(submitButton);

      // Activate indicator
      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.setAttribute("disabled", "true");

      // Dummy delay
      setTimeout(() => {
        // Send login request
        const formData = new FormData();
        const formats = {};
        for (const key in values) {
          if (key.split("-")[0] != "format") {
            if (key == "logo") {
              formData.append(key, this.file);
            } else {
              formData.append(key, values[key]);
            }
          } else {
            if (values[key] == null) {
              console.log("EMPTY values[key]");
            }
            formats[key.split("-")[1]] = values[key];
          }
        }
        if (Object.keys(formats).length > 0) {
          formData.append("formats", JSON.stringify(formats));
        }
        console.log(formData);
        //formData.append("test.txt", fs.createReadStream("./test.txt"));
        this.store
          .dispatch(Actions.REGISTER, formData)
          .then(() => {
            this.router.push({ name: "dashboard" });
          })
          .catch(() => {
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
        submitButton.removeAttribute("data-kt-indicator");
        submitButton.removeAttribute("disabled");
      }, 2000);
    },
  },
  setup() {
    const store = useStore();
    const gettext = useGettext();

    const phoneRegExp = /^([0-9+])[0-9 \\(\\)-]+$/;

    const checkIfFilesAreCorrectType = (files) => {
      if (files == null) {
        return true;
      }
      let lret = false;
      files.map((file) => {
        if (["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
          lret = true;
        }
      });
      return lret;
    };

    const registration = Yup.object().shape({
      code: Yup.string()
        .required()
        .label(gettext.$gettext("Photo studio name")),
      name: Yup.string().required().label(gettext.$gettext("First Name")),
      surname: Yup.string().required().label(gettext.$gettext("Last Name")),
      phone: Yup.string()
        .matches(phoneRegExp, gettext.$gettext("Phone number is not valid"))
        .min(4)
        .max(30)
        .required()
        .label(gettext.$gettext("Phone number")),
      contact: Yup.string()
        .required()
        .label(gettext.$gettext("Contact information")),
      logo: Yup.mixed()
        .test(
          "fileSize",
          gettext.$gettext("The file is too large. Allowed up to 10MB"),
          (value) => {
            if (!value || !value.length) return true;
            return value[0].size <= 10485760;
          }
        )
        .test(
          "correctFileType",
          gettext.$gettext("The file provided is not an image"),
          checkIfFilesAreCorrectType
        )
        .required()
        .label(gettext.$gettext("Logo")),
      toc: Yup.boolean()
        .required()
        .label(gettext.$gettext("Terms and conditions")),
    });

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
        value: "en",
      },
      sl: {
        flag: "media/flags/slovenia.svg",
        name: "Slovenščina",
        value: "sl",
      },
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      gettext.current = lang;
      store.dispatch(Actions.LANGUAGE_CHANGED);
    };

    const currentLanguage = (lang) => {
      return gettext.current === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries[gettext.current];
    });

    return {
      setLang,
      currentLanguage,
      currentLanguageLocale,
      registration,
      countries,
    };
  },
});
</script>
