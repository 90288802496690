
import KTFooter from "@/layout/footer/Footer.vue";
import { defineComponent, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import * as Yup from "yup";
import { useStore } from "vuex";
import { Actions } from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import { dynStr } from "@/core/services/DynamicTranslationService";
import FormData from "form-data";
import { useGettext } from "vue3-gettext";
import { useRouter } from "vue-router";

export default defineComponent({
  name: "sign-up",
  components: {
    KTFooter,
    Field,
    Form,
    ErrorMessage,
  },
  data() {
    const gettext = useGettext();
    const store = useStore();
    const router = useRouter();

    const languageFormat = [
      {
        id: 0,
        translations: [
          {
            /* eslint-disable @typescript-eslint/camelcase */
            languages_code: "en",
            title: "10 x 18 cm",
          },
        ],
      },
    ];
    const findDynStrById = function (id, trans, word) {
      for (const tran of trans) {
        if (tran.id == id) {
          return dynStr(tran.translations, word);
        }
      }
      return null;
    };
    const fileList = [];
    const file = "";
    const curLang = localStorage.getItem("lang")
      ? (localStorage.getItem("lang") as string)
      : "en";
    gettext.current = curLang;
    const value = [];
    return {
      store,
      router,
      file,
      fileList,
      curLang,
      value,
      languageFormat,
      dynStr,
      findDynStrById,
    };
  },
  created() {
    this.store.dispatch(Actions.FORMATS).then((result) => {
      this.languageFormat = result.data;
    });
  },
  methods: {
    handleFileUpload(event) {
      this.file = event.target.files[0];
    },
    onSubmitRegister(values, e) {
      console.log(values);
      console.log(e);
      //const submitButton = ref<HTMLElement | null>(null);
      const submitButton = e.evt.target as HTMLElement;
      console.log(submitButton);

      // Activate indicator
      submitButton.setAttribute("data-kt-indicator", "on");
      submitButton.setAttribute("disabled", "true");

      // Dummy delay
      setTimeout(() => {
        // Send login request
        const formData = new FormData();
        const formats = {};
        for (const key in values) {
          if (key.split("-")[0] != "format") {
            if (key == "logo") {
              formData.append(key, this.file);
            } else {
              formData.append(key, values[key]);
            }
          } else {
            if (values[key] == null) {
              console.log("EMPTY values[key]");
            }
            formats[key.split("-")[1]] = values[key];
          }
        }
        if (Object.keys(formats).length > 0) {
          formData.append("formats", JSON.stringify(formats));
        }
        console.log(formData);
        //formData.append("test.txt", fs.createReadStream("./test.txt"));
        this.store
          .dispatch(Actions.REGISTER, formData)
          .then(() => {
            this.router.push({ name: "dashboard" });
          })
          .catch(() => {
            Swal.fire({
              text: this.store.getters.getErrors,
              icon: "error",
              buttonsStyling: false,
              confirmButtonText: "Try again!",
              customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
              },
            });
          });
        submitButton.removeAttribute("data-kt-indicator");
        submitButton.removeAttribute("disabled");
      }, 2000);
    },
  },
  setup() {
    const store = useStore();
    const gettext = useGettext();

    const phoneRegExp = /^([0-9+])[0-9 \\(\\)-]+$/;

    const checkIfFilesAreCorrectType = (files) => {
      if (files == null) {
        return true;
      }
      let lret = false;
      files.map((file) => {
        if (["image/jpeg", "image/png", "image/webp"].includes(file.type)) {
          lret = true;
        }
      });
      return lret;
    };

    const registration = Yup.object().shape({
      code: Yup.string()
        .required()
        .label(gettext.$gettext("Photo studio name")),
      name: Yup.string().required().label(gettext.$gettext("First Name")),
      surname: Yup.string().required().label(gettext.$gettext("Last Name")),
      phone: Yup.string()
        .matches(phoneRegExp, gettext.$gettext("Phone number is not valid"))
        .min(4)
        .max(30)
        .required()
        .label(gettext.$gettext("Phone number")),
      contact: Yup.string()
        .required()
        .label(gettext.$gettext("Contact information")),
      logo: Yup.mixed()
        .test(
          "fileSize",
          gettext.$gettext("The file is too large. Allowed up to 10MB"),
          (value) => {
            if (!value || !value.length) return true;
            return value[0].size <= 10485760;
          }
        )
        .test(
          "correctFileType",
          gettext.$gettext("The file provided is not an image"),
          checkIfFilesAreCorrectType
        )
        .required()
        .label(gettext.$gettext("Logo")),
      toc: Yup.boolean()
        .required()
        .label(gettext.$gettext("Terms and conditions")),
    });

    const countries = {
      en: {
        flag: "media/flags/united-states.svg",
        name: "English",
        value: "en",
      },
      sl: {
        flag: "media/flags/slovenia.svg",
        name: "Slovenščina",
        value: "sl",
      },
    };

    const setLang = (lang) => {
      localStorage.setItem("lang", lang);
      gettext.current = lang;
      store.dispatch(Actions.LANGUAGE_CHANGED);
    };

    const currentLanguage = (lang) => {
      return gettext.current === lang;
    };

    const currentLanguageLocale = computed(() => {
      return countries[gettext.current];
    });

    return {
      setLang,
      currentLanguage,
      currentLanguageLocale,
      registration,
      countries,
    };
  },
});
