
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import { environment } from "@/environments/environment";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      url: environment.frontendUrl,
      footerWidthFluid,
    };
  },
});
