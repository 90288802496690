<template>
  <!--begin::Footer-->
  <div class="footer py-4 d-flex flex-lg-column" id="kt_footer">
    <!--begin::Container-->
    <div
      class="d-flex flex-column flex-md-row align-items-center justify-content-between"
      :class="{
        'container-fluid': footerWidthFluid,
        container: !footerWidthFluid,
      }"
    >
      <!--begin::Copyright-->
      <div class="text-dark order-2 order-md-1">
        <span class="fw-bold me-1">2022&copy;</span>
        <a :href="url" target="_blank" class="text-gray-800 text-hover-primary"
          >PrinteePRO</a
        >
      </div>
      <!--end::Copyright-->

      <!--begin::Menu-->
      <ul class="menu menu-gray-600 menu-hover-primary fw-bold order-1">
        <li class="menu-item">
          <a :href="url" target="_blank" class="menu-link px-2">{{
            $gettext("About")
          }}</a>
        </li>

        <li class="menu-item">
          <a href="mailto:support@printeepro.com" class="menu-link px-2">{{
            $gettext("Contact Us")
          }}</a>
        </li>

        <li class="menu-item">
          <a :href="`${url}/privacy`" target="_blank" class="menu-link px-2">{{
            $gettext("Privacy Policy")
          }}</a>
        </li>
      </ul>
      <!--end::Menu-->
    </div>
    <!--end::Container-->
  </div>
  <!--end::Footer-->
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { footerWidthFluid } from "@/core/helpers/config";
import { environment } from "@/environments/environment";

export default defineComponent({
  name: "KTFooter",
  setup() {
    return {
      url: environment.frontendUrl,
      footerWidthFluid,
    };
  },
});
</script>
